<template>
  <nav class="bg-white border-b border-gray-200">
    <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
      <div class="flex justify-between h-16">
        <div class="flex">
          <div class="flex-shrink-0 flex items-center">
            <!--            <img class="block lg:hidden h-8 w-auto" src="https://tailwindui.com/img/logos/workflow-mark-indigo-600.svg" alt="Workflow">-->
            <!--            <img class="hidden lg:block h-8 w-auto" src="https://tailwindui.com/img/logos/workflow-logo-indigo-600-mark-gray-800-text.svg" alt="Workflow">-->
            <img class="h-9 w-auto" :src="img_source" alt="" />
          </div>
          <div class="hidden sm:-my-px sm:ml-6 sm:flex sm:space-x-8">
            <!-- Current: "border-indigo-500 text-gray-900", Default: "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700" -->
            <router-link :to="{name: 'About'}">
              <a href="#" v-on:click="activeBtn = 'btn1'" :class="{ 'border-indigo-500': activeBtn === 'btn1', 'text-gray-900': activeBtn === 'btn1','border-transparent': activeBtn !== 'btn1', 'text-gray-500':activeBtn !== 'btn1'}" class="inline-flex items-center px-1 pt-1 border-b-2 text-lg font-bold h-full h-full" aria-current="page">
                簡介
              </a>
            </router-link>
            <router-link :to="{name: 'Platform'}">
              <a href="#" v-on:click="activeBtn = 'btn2'" :class="{ 'border-indigo-500': activeBtn === 'btn2', 'text-gray-900': activeBtn === 'btn2','border-transparent': activeBtn !== 'btn2', 'text-gray-500':activeBtn !== 'btn2'}" class="inline-flex items-center px-1 pt-1 border-b-2 text-lg font-bold h-full">
                操作紀錄
              </a>
            </router-link>
            <router-link :to="{name: 'Term'}">
              <a href="#" v-on:click="activeBtn = 'btn3'" :class="{ 'border-indigo-500': activeBtn === 'btn3', 'text-gray-900': activeBtn === 'btn3','border-transparent': activeBtn !== 'btn3', 'text-gray-500':activeBtn !== 'btn3'}" class="inline-flex items-center px-1 pt-1 border-b-2 text-lg font-bold h-full">
                操作詞彙
              </a>
            </router-link>
            <a target="_blank" href="https://www.test-cal.ntu.asia/" v-on:click="activeBtn = 'btn4'" :class="{ 'border-indigo-500': activeBtn === 'btn4', 'text-gray-900': activeBtn === 'btn4','border-transparent': activeBtn !== 'btn4', 'text-gray-500':activeBtn !== 'btn4'}" class="inline-flex items-center px-1 pt-1 border-b-2 text-lg font-bold">
              栽培曆
            </a>
            <a target="_blank" :href="traceabilityWebPageURL" v-on:click="activeBtn = 'btn5'" :class="{ 'border-indigo-500': activeBtn === 'btn5', 'text-gray-900': activeBtn === 'btn5','border-transparent': activeBtn !== 'btn5', 'text-gray-500':activeBtn !== 'btn5'}" class="inline-flex items-center px-1 pt-1 border-b-2 text-lg font-bold">
              電腦詳細頁面
            </a>
          </div>
        </div>
        <div class="-mr-2 flex items-center sm:hidden">
          <!-- Mobile menu button -->
          <button v-on:click="toggleNavbar" type="button" class="bg-white inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500" aria-controls="mobile-menu" aria-expanded="false">
            <span class="sr-only">Open main menu</span>
            <!--
              Heroicon name: outline/menu

              Menu open: "hidden", Menu closed: "block"
            -->
            <svg class="block h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h16" />
            </svg>
            <!--
              Heroicon name: outline/x

              Menu open: "block", Menu closed: "hidden"
            -->
            <svg class="hidden h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
        </div>
      </div>
    </div>

    <!-- Mobile menu, show/hide based on menu state. -->
    <div class="sm:hidden" id="mobile-menu">
      <div v-show="mobileShowMenu" class="pt-2 pb-3 space-y-1">
        <!-- Current: "bg-indigo-50 border-indigo-500 text-indigo-700", Default: "border-transparent text-gray-600 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-800" -->
        <router-link :to="{name: 'About'}">
          <a href="#" v-on:click="activeBtn = 'btn1'" :class="{ 'bg-indigo-50 border-indigo-500': activeBtn === 'btn1', 'border-indigo-500': activeBtn === 'btn1', 'text-indigo-700' : activeBtn === 'btn1', 'border-transparent': activeBtn !== 'btn1','text-gray-600': activeBtn !== 'btn1', 'hover:bg-gray-50': activeBtn !== 'btn1', 'hover:border-gray-300': activeBtn !== 'btn1', 'hover:text-gray-800': activeBtn !== 'btn1'}" class="block pl-3 pr-4 py-2 border-l-4 text-lg font-bold" aria-current="page">
            簡介
          </a>
        </router-link>
        <router-link :to="{name: 'Platform'}">
          <a href="#" v-on:click="activeBtn = 'btn2'" :class="{ 'bg-indigo-50 border-indigo-500': activeBtn === 'btn2', 'border-indigo-500': activeBtn === 'btn2', 'text-indigo-700' : activeBtn === 'btn2', 'border-transparent': activeBtn !== 'btn2','text-gray-600': activeBtn !== 'btn2', 'hover:bg-gray-50': activeBtn !== 'btn2', 'hover:border-gray-300': activeBtn !== 'btn2', 'hover:text-gray-800': activeBtn !== 'btn2'}" class="block pl-3 pr-4 py-2 border-l-4 text-lg font-bold">
            操作紀錄
          </a>
        </router-link>
        <router-link :to="{name: 'Term'}">
          <a href="#" v-on:click="activeBtn = 'btn3'" :class="{ 'bg-indigo-50 border-indigo-500': activeBtn === 'btn3', 'border-indigo-500': activeBtn === 'btn3', 'text-indigo-700' : activeBtn === 'btn3', 'border-transparent': activeBtn !== 'btn3','text-gray-600': activeBtn !== 'btn3', 'hover:bg-gray-50': activeBtn !== 'btn3', 'hover:border-gray-300': activeBtn !== 'btn3', 'hover:text-gray-800': activeBtn !== 'btn3'}" class="block pl-3 pr-4 py-2 border-l-4 text-lg font-bold">
          操作詞彙
          </a>
        </router-link>
        <a target="_blank" href="https://www.test-cal.ntu.asia/" v-on:click="activeBtn = 'btn4'" :class="{ 'bg-indigo-50 border-indigo-500': activeBtn === 'btn4', 'border-indigo-500': activeBtn === 'btn4', 'text-indigo-700' : activeBtn === 'btn4', 'border-transparent': activeBtn !== 'btn4','text-gray-600': activeBtn !== 'btn4', 'hover:bg-gray-50': activeBtn !== 'btn4', 'hover:border-gray-300': activeBtn !== 'btn4', 'hover:text-gray-800': activeBtn !== 'btn4'}" class="block pl-3 pr-4 py-2 border-l-4 text-lg font-bold">
          栽培曆
        </a>
        <a target="_blank" :href="traceabilityWebPageURL" v-on:click="activeBtn = 'btn5'" :class="{ 'bg-indigo-50 border-indigo-500': activeBtn === 'btn5', 'border-indigo-500': activeBtn === 'btn5', 'text-indigo-700' : activeBtn === 'btn5', 'border-transparent': activeBtn !== 'btn5','text-gray-600': activeBtn !== 'btn5', 'hover:bg-gray-50': activeBtn !== 'btn5', 'hover:border-gray-300': activeBtn !== 'btn5', 'hover:text-gray-800': activeBtn !== 'btn5'}" class="block pl-3 pr-4 py-2 border-l-4 text-lg font-bold">
          電腦詳細頁面
        </a>
      </div>
    </div>
  </nav>
</template>
<script>
import Config from '@/config/config'

export default {
  name: 'NavBarR',
  data: function () {
    return {
      img_source: require('../assets/icons/pure_logo.png'),
      mobileShowMenu: false,
      activeBtn: 'btn1',
      traceabilityWebPageURL: Config.traceabilityWebPageURL
    }
  },
  methods: {
    toggleNavbar: function () {
      this.mobileShowMenu = !this.mobileShowMenu
    }
  },
  created () {
    // console.log(this.$router.currentRoute.path)
    if (this.$router.currentRoute.path === '/about' || this.$router.currentRoute.path === '/') {
      // console.log('btn1')
      this.activeBtn = 'btn1'
    } else if (this.$router.currentRoute.path === '/platform') {
      // console.log('btn2')
      this.activeBtn = 'btn2'
    } else if (this.$router.currentRoute.path === '/term') {
      // console.log('btn3')
      this.activeBtn = 'btn3'
    }
  }
}
</script>

<style scoped>
.active {
  align-items: center;
  border-bottom-color: rgb(99, 102, 241);
  border-bottom-style: solid;
  border-bottom-width: 1.9px;
  border-left-color: rgb(99, 102, 241);
  border-left-style: solid;
  border-left-width: 0px;
  border-right-color: rgb(99, 102, 241);
  border-right-style: solid;
  border-right-width: 0px;
  border-top-color: rgb(99, 102, 241);
  border-top-style: solid;
  border-top-width: 0px;
  box-sizing: border-box;
  color: rgb(17, 24, 39);
  display: flex;
  font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  padding-left: 4px;
  padding-right: 4px;
  padding-top: 4px;
  tab-size: 4;
  text-decoration-color: rgb(17, 24, 39);
  text-decoration-line: none;
  text-decoration-style: solid;
  text-decoration-thickness: auto;
}
.default {
  align-items: center;
  border-bottom-color: rgba(0, 0, 0, 0);
  border-bottom-style: solid;
  border-bottom-width: 1.9px;
  border-left-color: rgba(0, 0, 0, 0);
  border-left-style: solid;
  border-left-width: 0px;
  border-right-color: rgba(0, 0, 0, 0);
  border-right-style: solid;
  border-right-width: 0px;
  border-top-color: rgba(0, 0, 0, 0);
  border-top-style: solid;
  border-top-width: 0px;
  box-sizing: border-box;
  color: rgb(107, 114, 128);
  display: flex;
  font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  margin-left: 32px;
  margin-right: 0px;
  padding-left: 4px;
  padding-right: 4px;
  padding-top: 4px;
  tab-size: 4;
  text-decoration-color: rgb(107, 114, 128);
  text-decoration-line: none;
  text-decoration-style: solid;
  text-decoration-thickness: auto;
}
</style>
